.payment-card-container {
  box-sizing: border-box;
  width: 100%;
  height: 308px;
  background: #ffffff;
  border-radius: 16px;
}

.payment-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.payment-title {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #1c1c1c;
}

.cursor-pointer {
  cursor: pointer;
}

.user-type{
  display: flex;
  justify-content: space-around;
}

.user-type div{
  display: flex;
  align-items: center;
  padding: 0 6px;
}
